body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
::-webkit-scrollbar {
	width: 8px; /* Width of the scrollbar */
	border-radius: 4px; /* Rounded corners */
  }
  
  ::-webkit-scrollbar-thumb {
	background-color: #f595ba; /* Color of the thumb (the draggable part) */
	border-radius: 25px; /* Rounded corners */
  }
.scrollbar {
	width: 8px;
	height: 1px;
	scrollbar-color: #f595ba transparent; /* Thumb color and track color */
  }
  
  .scrollbar-thumb {
	background-color: #f595ba;
  }
.custom-span {
	display: flex;
	justify-content: start;
	align-items: center;
	margin: 10px;
	margin-left: 0%;
	width: fit-content;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
	background-color: beige;
	font-size: 12px;
  }
  
  .custom-span a {
	text-decoration: none;
	color: #000;
	display: flex;
	padding: 7px;
	align-items: center;
  }
  
  .custom-span:hover {
	background-color: blanchedalmond;
  }
  
  .custom-span img {
	display: inline-block;
	width: 25px;
	height: auto;
  }
  
  .custom-span b {
	font-weight: bold;
	margin-left: 4px;
  }
  