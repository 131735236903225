.modal-container .modal-content {
	/* background: linear-gradient(90deg, #D5278F 0%, #2F3D95 95.61%); */
	/* background: linear-gradient(147.16deg, #FFE8E7 1.07%, #F1D6D4 100%); */
	/* display: flex;
	flex-direction: column;
	align-items: center; */
	/* padding: 45px 45px 60px; */
	gap: 1px;

	position: relative;
	border-radius: 20px;
}

.model-hading {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 39px;
	/* identical to box height */

	text-align: center;

	color: #FFFFFF;
}

.model-paragraph {

	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	/* identical to box height */
	color: #FFFFFF;

	text-align: center;

}

.top_section_pic {
	display: flex;
	justify-content: center;
	padding-top: 60px;
}

.contact-us-btn-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.btn_wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
}

.footer_part_pic {
	display: flex;
	justify-content: center;
}