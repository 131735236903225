@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", "Bebas+Neue";
}

.web_page {
  width: 100%;
  margin: auto;
}

.top_header_portion {
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
  background-attachment: fixed;
}

.top_section_pic img {
  width: 200px;
  margin-top: 14px;
  left: 650px;
  top: 60px;
}

.header_sections {
  width: 100%;
  margin: auto;
  display: flex;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-around;
  padding: 30px;
}

.left_section {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-align: center;
  padding: 10px 0px;

}

.left_section h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.left_section h1 {
  margin-left: -8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  line-height: 120%;
  margin-bottom: 15px;
  color: #ffffff;
}

.left_section img {
  width: 20px;
  height: 10px;
  opacity: 0.7;
}

.left_section p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 10px;
}

.left_section p.smll-widht {
  font-size: 18px;
  width: 100%;
  font-weight: 400;
  text-align: justify;
  font-family: "Montserrat";
  font-style: normal;
  text-align: center;
}

.btn_1 {
  font-size: 18px;
  width: 75%;
  margin-top: 10px;
  font-weight: 400;
  padding: 2%;
  border: 1px solid #ffffff;
  border-radius: 5px;
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 210.61%);
}

.btn_1:hover {
  color: red;
}

.btn_2 {
  font-size: 18px;
  width: 75%;
  margin-top: 10px;
  font-weight: 400;
  padding: 2%;
  border: 1px solid #ffffff;
  border-radius: 5px;
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 210.61%);
}

.right_section {
  position: relative;
  border-radius: 20px;
  width: auto;
  height: 580px;
  display: flex;
  justify-items: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 15px;
  background-position: center;

}

.right_section img {
  width: 115%;
  margin: auto;
  margin-top: 5%;
}

.circle1 {
  /* position: absolute; */
  margin-left: -34px;
  margin-right: -48px;
  width: 145%;
  height: 90%;
  display: flex;
  align-items: stretch;


}

.right_section_card_one {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 50%;
}

.right_section_card_two {
  display: flex;
  justify-content: start;
  align-items: end;
  width: 100%;
  height: 50%;
}

.main-card1 {
  width: 100%;
  height: 100%;
  top: 0px;
}

.main-card1 .card1 {
  width: 170px;
  height: 150px;
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
  bottom: 60px;
  left: 0px;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
}

.main-card1 .card1.card2 {
  left: inherit;
  right: 0px;
  bottom: inherit;
  top: -15px !important;
}

.card1 img {
  width: 25px;
}

.card1 h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  color: #ffffff;
  margin-bottom: 6px;
}

.card1 p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
  color: #ffffff;
}

.services {
  width: 80%;
  margin: auto;
}

.services_section h2 {
  margin-top: 8%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 130%;
  text-align: center;
  color: #010b19;
  margin-bottom: 4%;
}

.services_section p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 160%;
  text-align: center;
  color: #010b19;
  opacity: 0.7;
  width: 70%;
  margin: auto;
  margin-bottom: 8%;
}

.services_cards {
  width: 100%;
  margin: auto;
  display: flex;
  margin-bottom: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.services_cards_row_1 {
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
  padding: 8%;
  border-radius: 10px;
  overflow-y: auto;
}

.practices_cards_row_1 {
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
  padding: 5px;
  border-radius: 10px;
  overflow: hidden;
}

.practices_card_inner {
  display: flex;
  height: fit-content;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
}

.practices_card_inner:hover {
  transform: scale(1.1);
  backdrop-filter: blur(10px);

}

.practiceCTA {
  background-color: #010b19;
  color: #fcf4f9;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  gap: 10px;
  border-radius: 15px;
  border: 2px double #fa8a40c4;
  max-width: 240px;
  width: 35%;
  margin-bottom: 8%;
  text-transform: uppercase;
}

.practiceCTA:hover {
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
}

.services_cards_inner {
  display: flex;
  height: 192px;
}

.services_cards_inner img {
  margin-top: 2%;
  width: 44px;
  height: 44px;
}

.inner_section {
  text-align: left;
  margin-left: 6%;
}

.practices_card_inner li,
.services_cards_inner li {
  font-family: "Montserrat";
  font-family: "Montserrat";
  list-style-type: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 8px;
}

.services_cards_inner p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 160%;
  opacity: 0.9;
  color: #ffffff;
  text-align: left;
}

.btn_10 {
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  gap: 10px;
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 35%;
  margin-bottom: 8%;
}

span {
  color: #ffffff;
}

.Medical_Billing {
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
  width: 100%;
  height: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Medical_Billing_head h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  padding-top: 50px;
}

.Medical_Billing_head p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  width: 70%;
  margin: auto;
  padding-top: 10px;
}

.Medical_Billing_cards_row1 {
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3%;
  margin-bottom: 4%;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1440px;
}

.medical_bill {

  background-size: 100% auto;
  background-position: center;
}

.Medical_Billing_card {
  width: 100%;
  height: 219px;
  backdrop-filter: blur(2px) brightness(30%);
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.list_medical {
  height: initial;
  width: 100%;
}

.medical_dialog {
  width: 100%;
  overflow: hidden;
}

.medical_dialog h3 {
  text-align: center;
  color: #fff;
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
}

.medical_dialog img {
  border-radius: 0%;
  height: 320px;
  width: auto;
}

.medical_dialog p {
  text-align: justify;
}

.Medical_Billing_card li {
  font-family: "Arial";
  list-style-type: none;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #fff;
  /* background-color: #010b19; */
}

.Medical_Billing_card p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #ffffff;
  background-color: transparent;
  text-align: left;
  position: absolute;
  bottom: 0%;
  opacity: .5;
}



.Medical_Billing_card12 h1 {
  width: 100%;

  padding: 7px;
  text-align: left;
  font-family: "Bebas Neue";
  font-size: 60px;
  font-weight: 500;
  height: 219px;
  background: #ffffff;
  border-radius: 10px;
}

.Medical_Billing_card12 h1:hover {
  width: 100%;
  padding: 7px;
  padding-bottom: 8px;
  font-family: "Bebas Neue";
  font-size: 60px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #ffffff;

}



.account_text {
  margin: 0%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 160%;
  width: 100vw;
  background-attachment: fixed;
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mydiv {

  margin: 5%;
  padding: 12px;
  border-radius: 25px;
  background: linear-gradient(105deg, #0003, #3333, #0003);
  font-family: "Montserrat";
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.account_text h3 {
  width: 100%;
  font-size: 36px;
  letter-spacing: 0.1rem;
  font-weight: bolder;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  padding: 20px 0px;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(90deg, #d5278f, #2f3d95);
  -webkit-text-fill-color: transparent;
}

.account_text p {
  font-style: normal;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #fff;
  text-align: justify;
  padding-bottom: 40px;
  max-width: 1080px;
}

.btn_11 {
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  background: linear-gradient(45deg, #4ddc14, #008b07, #6edc14);
  border: 1px solid transparent;
  border-radius: 25px;
  width: 25%;
  min-width: fit-content;
  margin-bottom: 8%;
}

.receivable h3 {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  font-family: "Montserrat";
  font-size: 40px;
  font-weight: 600;
  padding-top: 50px;
  padding-bottom: 20px;
}

.receivable_grid {
  width: 100%;
  margin: auto 10px;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  font-family: "Montserrat";
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 160%;
  display: grid;
  justify-content: space-evenly;
  margin-left: 10px;
}

.left_receivable {
  background-color: #fcf4f9;
  padding: 40px 50px;
  border-radius: 10px;
  width: 100%;
  margin: 0px 10px;
}

.left_receivable h4 {
  padding-top: 30px;
  color: #df7ebbf5;
}

.left_receivable p {
  font-weight: 500;
  font-size: 15px;
}

.right_receivable {
  background-color: #fcf4f9;
  padding: 40px 50px;
  border-radius: 10px;
}

.right_receivable h4 {
  padding-top: 30px;
  color: #df7ebbf5;
}

.right_receivable p {
  font-weight: 500;
  font-size: 15px;
}

.btn_4 {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  gap: 10px;
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 30%;
  margin-bottom: 8%;
}

span {
  color: #ffffff;
}

.medical_coding {
  background-image: url("./images/coding.webp");
  background-size: cover;
  background-repeat: no-repeat;

}

.medical_width {
  width: 80%;
  margin: auto;
}

.medical_width h1 {
  color: #ffffff;
  font-size: 50px;
  font-weight: 700;
  padding-top: 8%;
  display: flex;
  justify-content: center;
}

.medical_width p {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  padding: 5% 0%;
  width: 80%;
  margin: auto;
}

.medical_icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  display: flex;
  justify-content: space-evenly;
}

.first_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.medical_icons h5 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  padding-top: 8px;
}

.btn_5_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn_5 {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 5px;
  min-width: 35%;
  margin: 5% 0%;
}

span {
  color: #ffffff;
}

.Credentialing {
  width: 100%;
  margin: auto;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  margin-top: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
}

.left_Credentialing {
  text-align: left;
  padding: 30px;
  height: 100%;
}

.Credentialing h1 {
  margin-bottom: 7%;
}

.Credentialing p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
  line-height: 160%;
  color: #010b19;
}

.left_Credentialing ul li {
  line-height: 30px;
  margin-left: 30px;
}

.btn_6 {
  margin-top: 9%;
  font-size: 18px;
  width: 100%;
  font-weight: 400;
  padding: 2%;
  border: 1px solid #ffffff;
  border-radius: 5px;
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
}

.right_Credentialing {
  width: 100%;
  height: 100%;


}

.left_image123 {
  height: 100%;
  width: 100%;
}

.left_image12 {
  height: 100%;
  width: 48%;

}

.two_images {
  height: 50%;
  width: 100%;
}

.right_Credentialing_pics {
  position: relative;
  width: 100%;
  margin: 5px;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 15%;
  display: flex;
  justify-content: space-between;


}

.right_Credentialing_pic3 {
  margin-top: 7%;
}

.contact {
  width: 100%;
  margin: auto;
  padding: 5%;
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.contact p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.contact h1 {
  margin-top: 1%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 59px;
  text-align: center;
  color: #ffffff;
}

.search_items {
  position: relative;
  max-width: 680px;
  margin: auto;
  margin-top: 15px;
  max-width: 450px;
}

input {
  width: 100%;
  margin: auto;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
}

.search_btn {
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  height: 48px;
  color: #ffffff;
  position: absolute;
  top: 1px;
  right: 1px;
  border: none;
  border-radius: 0px 10px 10px 0px;
  padding: 0px 18px;
}

.divider {
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%);
  margin-top: 119px;
}

.social_media {
  text-align: center;
  margin-top: 25px;
}

.social_media li {
  display: inline-block;
  list-style: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid silver;
  margin: 0px 10px;
}

.social_media li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid silver;
}

.footer_part {
  padding: 35px 0px;
}

.form-control::-webkit-input-placeholder {
  /* WebKit, Blink, Edge,Chrome */
  color: green;
}

.footer_part_pic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  /* gap: 5px; */
}

.contact-email-cotainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 5px;
}

.contact-container h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  margin-top: 5px;
}